// https :https://framerusercontent.com/modules/rSbiLfsnHLTrF3vDy2qI/wwPZkJ3NWmc60aYUIYo1/eshFTHo2K.js
import { fontStore, } from 'unframer/dist/framer';
fontStore.loadWebFontsFromSelectors(['GF;DM Sans-regular',],);
var fonts = [{
  family: 'DM Sans',
  moduleAsset: {
    localModuleIdentifier: 'local-module:css/eshFTHo2K:default',
    url: 'https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxhTmf3ZGMZpg.ttf',
  },
  style: 'normal',
  url: 'https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxhTmf3ZGMZpg.ttf',
  weight: '400',
},];
var css = [
  '.framer-kEfpu .framer-styles-preset-1s3kmoj:not(.rich-text-wrapper), .framer-kEfpu .framer-styles-preset-1s3kmoj.rich-text-wrapper p { --framer-font-family: "DM Sans", "DM Sans Placeholder", sans-serif; --framer-font-size: 16px; --framer-font-style: normal; --framer-font-weight: 400; --framer-letter-spacing: -0.2px; --framer-line-height: 26px; --framer-paragraph-spacing: 20px; --framer-text-alignment: start; --framer-text-color: var(--token-67c1333b-4249-4ff1-a333-3581964020b4, #ffffff); --framer-text-decoration: none; --framer-text-transform: none; }',
];
var className = 'framer-kEfpu';

export { className, css, fonts, };
