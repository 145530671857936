'use client';
import { stdin_default, } from './chunk-WEMYXMB6.js';
import { className, className2, css, css2, fonts, fonts2, } from './chunk-CN24CVGW.js';

// https :https://framerusercontent.com/modules/nlM8JOvDr928PGHfGRto/rsSAJgyNhxibk505Z5yE/DxJuV9CJ2.js
import { jsx as _jsx2, jsxs as _jsxs, } from 'react/jsx-runtime';
import {
  addFonts as addFonts2,
  addPropertyControls as addPropertyControls2,
  ComponentViewportProvider,
  ControlType as ControlType2,
  cx as cx2,
  getFonts,
  getFontsFromSharedStyle,
  Link as Link2,
  RichText as RichText2,
  SVG,
  useActiveVariantCallback,
  useComponentViewport,
  useLocaleInfo as useLocaleInfo2,
  useVariantState as useVariantState2,
  withCSS as withCSS2,
} from 'unframer/dist/framer';
import { LayoutGroup as LayoutGroup2, motion as motion2, MotionConfigContext as MotionConfigContext2, } from 'unframer';
import * as React2 from 'react';

// https :https://framerusercontent.com/modules/EzSOmyKIOAQXoj7XFMT1/kkJ4wSole3ejG4GD81wT/lzIm5GN0y.js
import { fontStore, } from 'unframer/dist/framer';
fontStore.loadWebFontsFromSelectors([],);
var fonts3 = [];
var css3 = [
  '.framer-GU4ah .framer-styles-preset-svh859:not(.rich-text-wrapper), .framer-GU4ah .framer-styles-preset-svh859.rich-text-wrapper a { --framer-link-current-text-decoration: underline; --framer-link-hover-text-color: #0088ff; --framer-link-hover-text-decoration: none; --framer-link-text-decoration: none; }',
];
var className3 = 'framer-GU4ah';

// https :https://framerusercontent.com/modules/ikrkjozrPSyinmn7L7g9/n9HpYIlGzZjeGFkDtXFb/x2GOdaUSJ.js
import { jsx as _jsx, } from 'react/jsx-runtime';
import {
  addFonts,
  addPropertyControls,
  ControlType,
  cx,
  Link,
  RichText,
  useLocaleInfo,
  useVariantState,
  withCSS,
} from 'unframer/dist/framer';
import { LayoutGroup, motion, MotionConfigContext, } from 'unframer';
import * as React from 'react';
var enabledGestures = { HO1GLUdaI: { hover: true, }, };
var cycleOrder = ['HO1GLUdaI', 'p7K7sDbrV',];
var variantClassNames = { HO1GLUdaI: 'framer-v-16yj337', p7K7sDbrV: 'framer-v-1ksmaol', };
function addPropertyOverrides(overrides, ...variants) {
  const nextOverrides = {};
  variants === null || variants === void 0
    ? void 0
    : variants.forEach((variant,) => variant && Object.assign(nextOverrides, overrides[variant],));
  return nextOverrides;
}
var transitions = { default: { damping: 60, delay: 0, mass: 1, stiffness: 500, type: 'spring', }, };
var Transition = ({ value, children, },) => {
  const config = React.useContext(MotionConfigContext,);
  const transition = value !== null && value !== void 0 ? value : config.transition;
  const contextValue = React.useMemo(() => ({ ...config, transition, }), [JSON.stringify(transition,),],);
  return /* @__PURE__ */ _jsx(MotionConfigContext.Provider, { value: contextValue, children, },);
};
var humanReadableVariantMap = { 'Desktop & Tablet': 'HO1GLUdaI', Mobile: 'p7K7sDbrV', };
var getProps = ({ buttonText, height, id, link, width, ...props },) => {
  var ref, _variant, ref1;
  return {
    ...props,
    b2UEbvjCz: (ref = buttonText !== null && buttonText !== void 0 ? buttonText : props.b2UEbvjCz) !== null && ref !== void 0
      ? ref
      : 'Home',
    HJzZlQm7p: link !== null && link !== void 0 ? link : props.HJzZlQm7p,
    variant:
      (ref1 = (_variant = humanReadableVariantMap[props.variant]) !== null && _variant !== void 0 ? _variant : props.variant) !== null &&
        ref1 !== void 0
        ? ref1
        : 'HO1GLUdaI',
  };
};
var createLayoutDependency = (props, variants,) => variants.join('-',) + props.layoutDependency;
var Component = /* @__PURE__ */ React.forwardRef(function (props, ref,) {
  const { activeLocale, } = useLocaleInfo();
  const { style, className: className4, layoutId, variant, b2UEbvjCz, HJzZlQm7p, ...restProps } = getProps(props,);
  const { baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants, } = useVariantState({
    cycleOrder,
    defaultVariant: 'HO1GLUdaI',
    enabledGestures,
    transitions,
    variant,
    variantClassNames,
  },);
  const layoutDependency = createLayoutDependency(props, variants,);
  const defaultLayoutId = React.useId();
  return /* @__PURE__ */ _jsx(LayoutGroup, {
    id: layoutId !== null && layoutId !== void 0 ? layoutId : defaultLayoutId,
    children: /* @__PURE__ */ _jsx(motion.div, {
      initial: variant,
      animate: variants,
      onHoverStart: () => setGestureState({ isHovered: true, },),
      onHoverEnd: () => setGestureState({ isHovered: false, },),
      onTapStart: () => setGestureState({ isPressed: true, },),
      onTap: () => setGestureState({ isPressed: false, },),
      onTapCancel: () => setGestureState({ isPressed: false, },),
      className: cx('framer-0Hu7U', className, className2, classNames,),
      style: { display: 'contents', },
      children: /* @__PURE__ */ _jsx(Transition, {
        value: transition,
        children: /* @__PURE__ */ _jsx(Link, {
          href: HJzZlQm7p,
          openInNewTab: false,
          smoothScroll: true,
          children: /* @__PURE__ */ _jsx(motion.a, {
            ...restProps,
            className: `${cx('framer-16yj337', className4,)} framer-zgm8s0`,
            'data-framer-name': 'Desktop & Tablet',
            layoutDependency,
            layoutId: 'HO1GLUdaI',
            ref,
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              ...style,
            },
            variants: {
              'HO1GLUdaI-hover': { backgroundColor: 'var(--token-1dd1b262-9525-4cb7-b436-26fa4a3e5125, rgba(255, 255, 255, 0.1))', },
              p7K7sDbrV: { backgroundColor: 'rgba(0, 0, 0, 0)', },
            },
            ...addPropertyOverrides(
              { 'HO1GLUdaI-hover': { 'data-framer-name': void 0, }, p7K7sDbrV: { 'data-framer-name': 'Mobile', }, },
              baseVariant,
              gestureVariant,
            ),
            children: /* @__PURE__ */ _jsx(RichText, {
              __fromCanvasComponent: true,
              children: /* @__PURE__ */ _jsx(React.Fragment, {
                children: /* @__PURE__ */ _jsx(motion.p, {
                  className: 'framer-styles-preset-tdptso',
                  'data-styles-preset': 'Q4kC2bTJ2',
                  style: { '--framer-text-color': 'var(--extracted-r6o4lv)', },
                  children: 'Home',
                },),
              },),
              className: 'framer-g2cfcu',
              'data-framer-name': 'Main',
              layoutDependency,
              layoutId: 'I1:1295;54:6343',
              style: {
                '--extracted-r6o4lv': 'var(--token-67c1333b-4249-4ff1-a333-3581964020b4, rgb(255, 255, 255)) ',
                '--framer-paragraph-spacing': '0px',
              },
              text: b2UEbvjCz,
              variants: { p7K7sDbrV: { '--extracted-r6o4lv': 'var(--token-67c1333b-4249-4ff1-a333-3581964020b4, rgb(255, 255, 255))', }, },
              verticalAlignment: 'top',
              withExternalLayout: true,
              ...addPropertyOverrides(
                {
                  p7K7sDbrV: {
                    children: /* @__PURE__ */ _jsx(React.Fragment, {
                      children: /* @__PURE__ */ _jsx(motion.p, {
                        className: 'framer-styles-preset-de78pk',
                        'data-styles-preset': 'aLfVqe1FC',
                        style: { '--framer-text-alignment': 'center', '--framer-text-color': 'var(--extracted-r6o4lv)', },
                        children: 'Home',
                      },),
                    },),
                  },
                },
                baseVariant,
                gestureVariant,
              ),
            },),
          },),
        },),
      },),
    },),
  },);
},);
var css4 = [
  '.framer-0Hu7U [data-border="true"]::after { content: ""; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }',
  '@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }',
  '.framer-0Hu7U .framer-zgm8s0 { display: block; }',
  '.framer-0Hu7U .framer-16yj337 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 8px 16px 8px 16px; position: relative; text-decoration: none; width: min-content; }',
  '.framer-0Hu7U .framer-g2cfcu { flex: none; height: auto; position: relative; white-space: pre; width: auto; }',
  '.framer-0Hu7U .framer-v-16yj337 .framer-16yj337 { cursor: pointer; }',
  '@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-0Hu7U .framer-16yj337 { gap: 0px; } .framer-0Hu7U .framer-16yj337 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-0Hu7U .framer-16yj337 > :first-child { margin-left: 0px; } .framer-0Hu7U .framer-16yj337 > :last-child { margin-right: 0px; } }',
  '.framer-0Hu7U.framer-v-1ksmaol .framer-16yj337 { justify-content: center; }',
  ...css,
  ...css2,
];
var Framerx2GOdaUSJ = withCSS(Component, css4, 'framer-0Hu7U',);
var stdin_default2 = Framerx2GOdaUSJ;
Framerx2GOdaUSJ.displayName = 'Navbar Link Item';
Framerx2GOdaUSJ.defaultProps = { height: 38, width: 75, };
addPropertyControls(Framerx2GOdaUSJ, {
  variant: {
    options: ['HO1GLUdaI', 'p7K7sDbrV',],
    optionTitles: ['Desktop & Tablet', 'Mobile',],
    title: 'Variant',
    type: ControlType.Enum,
  },
  b2UEbvjCz: { defaultValue: 'Home', displayTextArea: false, title: 'Button Text', type: ControlType.String, },
  HJzZlQm7p: { title: 'Link', type: ControlType.Link, },
},);
addFonts(Framerx2GOdaUSJ, [...fonts, ...fonts2,],);

// https :https://framerusercontent.com/modules/nlM8JOvDr928PGHfGRto/rsSAJgyNhxibk505Z5yE/DxJuV9CJ2.js
var NavbarLinkItemFonts = getFonts(stdin_default2,);
var ButtonFonts = getFonts(stdin_default,);
var cycleOrder2 = ['xwBQGw0tJ', 'SvR1VZu54', 'V8F1cMoI1', 'X7UmP87j7',];
var serializationHash = 'framer-cg3tY';
var variantClassNames2 = {
  SvR1VZu54: 'framer-v-4xgb8g',
  V8F1cMoI1: 'framer-v-z75vc4',
  X7UmP87j7: 'framer-v-1ai4azp',
  xwBQGw0tJ: 'framer-v-kpfe1u',
};
function addPropertyOverrides2(overrides, ...variants) {
  const nextOverrides = {};
  variants === null || variants === void 0
    ? void 0
    : variants.forEach((variant,) => variant && Object.assign(nextOverrides, overrides[variant],));
  return nextOverrides;
}
var transition1 = { damping: 60, delay: 0, mass: 1, stiffness: 500, type: 'spring', };
var Transition2 = ({ value, children, },) => {
  const config = React2.useContext(MotionConfigContext2,);
  const transition = value !== null && value !== void 0 ? value : config.transition;
  const contextValue = React2.useMemo(() => ({ ...config, transition, }), [JSON.stringify(transition,),],);
  return /* @__PURE__ */ _jsx2(MotionConfigContext2.Provider, { value: contextValue, children, },);
};
var Variants = motion2(React2.Fragment,);
var humanReadableVariantMap2 = { 'Mobile Open': 'X7UmP87j7', Desktop: 'xwBQGw0tJ', Mobile: 'V8F1cMoI1', Tablet: 'SvR1VZu54', };
var getProps2 = ({ height, id, logoLink, pricingLink, signup, signupTitle, textToSpeechLink, width, ...props },) => {
  var _humanReadableVariantMap_props_variant, _ref, _ref1;
  return {
    ...props,
    HBUTJ3xey: pricingLink !== null && pricingLink !== void 0 ? pricingLink : props.HBUTJ3xey,
    Nu3nX8xNj: signup !== null && signup !== void 0 ? signup : props.Nu3nX8xNj,
    variant:
      (_ref =
            (_humanReadableVariantMap_props_variant = humanReadableVariantMap2[props.variant]) !== null &&
              _humanReadableVariantMap_props_variant !== void 0
              ? _humanReadableVariantMap_props_variant
              : props.variant) !== null && _ref !== void 0
        ? _ref
        : 'xwBQGw0tJ',
    xgYlpsZOl: textToSpeechLink !== null && textToSpeechLink !== void 0 ? textToSpeechLink : props.xgYlpsZOl,
    XqvwP0xPg: logoLink !== null && logoLink !== void 0 ? logoLink : props.XqvwP0xPg,
    z8b8c67Em: (_ref1 = signupTitle !== null && signupTitle !== void 0 ? signupTitle : props.z8b8c67Em) !== null && _ref1 !== void 0
      ? _ref1
      : 'Signup or Login',
  };
};
var createLayoutDependency2 = (props, variants,) => variants.join('-',) + props.layoutDependency;
var Component2 = /* @__PURE__ */ React2.forwardRef(function (props, ref,) {
  const { activeLocale, setLocale, } = useLocaleInfo2();
  const { style, className: className4, layoutId, variant, Nu3nX8xNj, xgYlpsZOl, HBUTJ3xey, XqvwP0xPg, z8b8c67Em, ...restProps } =
    getProps2(props,);
  const { baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants, } = useVariantState2({
    cycleOrder: cycleOrder2,
    defaultVariant: 'xwBQGw0tJ',
    variant,
    variantClassNames: variantClassNames2,
  },);
  const layoutDependency = createLayoutDependency2(props, variants,);
  const { activeVariantCallback, delay, } = useActiveVariantCallback(baseVariant,);
  const onTapt7xwa1 = activeVariantCallback(async (...args) => {
    setVariant('X7UmP87j7',);
  },);
  const onTap6vgbuw = activeVariantCallback(async (...args) => {
    setVariant('V8F1cMoI1',);
  },);
  const HihQ7sLzJoivdpd = activeVariantCallback(async (...args) => {
    if (Nu3nX8xNj) {
      const res = await Nu3nX8xNj(...args,);
      if (res === false) {
        return false;
      }
    }
  },);
  const ref1 = React2.useRef(null,);
  const isDisplayed = () => {
    if (['V8F1cMoI1', 'X7UmP87j7',].includes(baseVariant,)) {
      return true;
    }
    return false;
  };
  const isDisplayed1 = () => {
    if (baseVariant === 'V8F1cMoI1') {
      return true;
    }
    return false;
  };
  const isDisplayed2 = () => {
    if (baseVariant === 'X7UmP87j7') {
      return true;
    }
    return false;
  };
  const isDisplayed3 = () => {
    if (baseVariant === 'V8F1cMoI1') {
      return false;
    }
    return true;
  };
  const defaultLayoutId = React2.useId();
  const sharedStyleClassNames = [className3,];
  const componentViewport = useComponentViewport();
  return /* @__PURE__ */ _jsx2(LayoutGroup2, {
    id: layoutId !== null && layoutId !== void 0 ? layoutId : defaultLayoutId,
    children: /* @__PURE__ */ _jsx2(Variants, {
      animate: variants,
      initial: false,
      children: /* @__PURE__ */ _jsx2(Transition2, {
        value: transition1,
        children: /* @__PURE__ */ _jsx2(motion2.div, {
          ...restProps,
          className: cx2(serializationHash, ...sharedStyleClassNames, 'framer-kpfe1u', className4, classNames,),
          'data-border': true,
          'data-framer-name': 'Desktop',
          layoutDependency,
          layoutId: 'xwBQGw0tJ',
          onHoverEnd: () => setGestureState({ isHovered: false, },),
          onHoverStart: () => setGestureState({ isHovered: true, },),
          onTap: () => setGestureState({ isPressed: false, },),
          onTapCancel: () => setGestureState({ isPressed: false, },),
          onTapStart: () => setGestureState({ isPressed: true, },),
          ref: ref !== null && ref !== void 0 ? ref : ref1,
          style: {
            '--border-bottom-width': '0px',
            '--border-color': 'rgba(0, 0, 0, 0.1)',
            '--border-left-width': '0px',
            '--border-right-width': '0px',
            '--border-style': 'solid',
            '--border-top-width': '0px',
            ...style,
          },
          ...addPropertyOverrides2(
            {
              SvR1VZu54: { 'data-framer-name': 'Tablet', },
              V8F1cMoI1: { 'data-framer-name': 'Mobile', },
              X7UmP87j7: { 'data-framer-name': 'Mobile Open', },
            },
            baseVariant,
            gestureVariant,
          ),
          children: /* @__PURE__ */ _jsxs(motion2.div, {
            className: 'framer-126at8h',
            'data-framer-name': 'Navigation Content',
            layoutDependency,
            layoutId: 'ps7lPis8b',
            children: [
              /* @__PURE__ */ _jsxs(motion2.div, {
                className: 'framer-1whzqmc',
                'data-framer-name': 'Left Side',
                layoutDependency,
                layoutId: 'pQdRTwRV4',
                children: [
                  /* @__PURE__ */ _jsx2(motion2.div, {
                    className: 'framer-uu5sxp',
                    layoutDependency,
                    layoutId: 'BHlpMMDVQ',
                    children: /* @__PURE__ */ _jsx2(RichText2, {
                      __fromCanvasComponent: true,
                      children: /* @__PURE__ */ _jsx2(React2.Fragment, {
                        children: /* @__PURE__ */ _jsx2(motion2.p, {
                          style: {
                            '--font-selector': 'R0Y7SW50ZXItNzAw',
                            '--framer-font-family': '"Inter", "Inter Placeholder", sans-serif',
                            '--framer-font-size': '24px',
                            '--framer-font-weight': '700',
                            '--framer-text-color':
                              'var(--extracted-r6o4lv, var(--token-67c1333b-4249-4ff1-a333-3581964020b4, rgb(255, 255, 255)))',
                          },
                          children: /* @__PURE__ */ _jsx2(Link2, {
                            href: XqvwP0xPg,
                            openInNewTab: false,
                            smoothScroll: false,
                            children: /* @__PURE__ */ _jsx2(motion2.a, {
                              className: 'framer-styles-preset-svh859',
                              'data-styles-preset': 'lzIm5GN0y',
                              children: 'gesserit.',
                            },),
                          },),
                        },),
                      },),
                      className: 'framer-6fu098',
                      fonts: ['GF;Inter-700',],
                      layoutDependency,
                      layoutId: 'iUp4_QTQx',
                      style: { '--extracted-r6o4lv': 'var(--token-67c1333b-4249-4ff1-a333-3581964020b4, rgb(255, 255, 255))', },
                      verticalAlignment: 'top',
                      withExternalLayout: true,
                    },),
                  },),
                  isDisplayed() && /* @__PURE__ */ _jsxs(motion2.div, {
                    className: 'framer-n8ydhb',
                    'data-framer-name': 'MenuTrigger',
                    layoutDependency,
                    layoutId: 'uZsyhXNh5',
                    ...addPropertyOverrides2(
                      {
                        V8F1cMoI1: { 'data-highlight': true, onTap: onTapt7xwa1, },
                        X7UmP87j7: { 'data-highlight': true, onTap: onTap6vgbuw, },
                      },
                      baseVariant,
                      gestureVariant,
                    ),
                    children: [
                      isDisplayed1() && /* @__PURE__ */ _jsx2(SVG, {
                        className: 'framer-1lyg715',
                        layout: 'position',
                        layoutDependency,
                        layoutId: 'EHQwiB_O5',
                        opacity: 1,
                        svg:
                          '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 23 22"><path d="M 1 3 C 1 2.448 1.448 2 2 2 L 20 2 C 20.552 2 21 2.448 21 3 L 21 4 C 21 4.552 20.552 5 20 5 L 2 5 C 1.448 5 1 4.552 1 4 Z" fill="hsl(0, 0%, 100%)"></path><path d="M 1 10 C 1 9.448 1.448 9 2 9 L 20 9 C 20.552 9 21 9.448 21 10 L 21 11 C 21 11.552 20.552 12 20 12 L 2 12 C 1.448 12 1 11.552 1 11 Z" fill="hsl(0, 0%, 100%)"></path><path d="M 1 17 C 1 16.448 1.448 16 2 16 L 20 16 C 20.552 16 21 16.448 21 17 L 21 18 C 21 18.552 20.552 19 20 19 L 2 19 C 1.448 19 1 18.552 1 18 Z" fill="hsl(0, 0%, 100%)"></path></svg>',
                        svgContentId: 3467333819,
                        withExternalLayout: true,
                        ...addPropertyOverrides2({ V8F1cMoI1: { svgContentId: 3714056781, }, }, baseVariant, gestureVariant,),
                      },),
                      isDisplayed2() && /* @__PURE__ */ _jsx2(SVG, {
                        className: 'framer-1r15ghg',
                        layout: 'position',
                        layoutDependency,
                        layoutId: 'T2bz7iOf7',
                        opacity: 1,
                        svg:
                          '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 25 22"><path d="M 0 1 C 0 0.448 0.448 0 1 0 L 20 0 C 20.552 0 21 0.448 21 1 L 21 3 C 21 3.552 20.552 4 20 4 L 1 4 C 0.448 4 0 3.552 0 3 Z" transform="translate(1.5 9) rotate(45 10.5 2)" fill="hsl(0, 0%, 100%)"></path><path d="M 0 1 C 0 0.448 0.448 0 1 0 L 20 0 C 20.552 0 21 0.448 21 1 L 21 3 C 21 3.552 20.552 4 20 4 L 1 4 C 0.448 4 0 3.552 0 3 Z" transform="translate(2 9) rotate(-45 10.5 2)" fill="hsl(0, 0%, 100%)"></path></svg>',
                        svgContentId: 1417165255,
                        withExternalLayout: true,
                        ...addPropertyOverrides2({ X7UmP87j7: { svgContentId: 13681087, }, }, baseVariant, gestureVariant,),
                      },),
                    ],
                  },),
                ],
              },),
              isDisplayed3() && /* @__PURE__ */ _jsxs(motion2.div, {
                className: 'framer-3o3h6y',
                'data-framer-name': 'Center',
                layoutDependency,
                layoutId: 'Fmh_v78uN',
                children: [
                  /* @__PURE__ */ _jsx2(ComponentViewportProvider, {
                    children: /* @__PURE__ */ _jsx2(motion2.div, {
                      className: 'framer-1516upi-container',
                      layoutDependency,
                      layoutId: 'aaCkFVFOr-container',
                      children: /* @__PURE__ */ _jsx2(stdin_default2, {
                        buttonText: 'Pricing',
                        height: '100%',
                        id: 'aaCkFVFOr',
                        layoutId: 'aaCkFVFOr',
                        link: HBUTJ3xey,
                        variant: 'HO1GLUdaI',
                        width: '100%',
                        ...addPropertyOverrides2(
                          { X7UmP87j7: { style: { width: '100%', }, variant: 'p7K7sDbrV', }, },
                          baseVariant,
                          gestureVariant,
                        ),
                      },),
                    },),
                  },),
                  /* @__PURE__ */ _jsx2(ComponentViewportProvider, {
                    children: /* @__PURE__ */ _jsx2(motion2.div, {
                      className: 'framer-7hzws2-container',
                      layoutDependency,
                      layoutId: 'aCK3blECX-container',
                      children: /* @__PURE__ */ _jsx2(stdin_default2, {
                        buttonText: 'Text To Speech',
                        height: '100%',
                        id: 'aCK3blECX',
                        layoutId: 'aCK3blECX',
                        link: xgYlpsZOl,
                        variant: 'HO1GLUdaI',
                        width: '100%',
                        ...addPropertyOverrides2(
                          { X7UmP87j7: { style: { width: '100%', }, variant: 'p7K7sDbrV', }, },
                          baseVariant,
                          gestureVariant,
                        ),
                      },),
                    },),
                  },),
                ],
              },),
              isDisplayed3() && /* @__PURE__ */ _jsx2(motion2.div, {
                className: 'framer-10slqsx',
                'data-framer-name': 'Right Side',
                layoutDependency,
                layoutId: 'lCwLoDLaM',
                children: /* @__PURE__ */ _jsx2(ComponentViewportProvider, {
                  children: /* @__PURE__ */ _jsx2(motion2.div, {
                    className: 'framer-1wyhldt-container',
                    layoutDependency,
                    layoutId: 'XqmQ_dCHS-container',
                    children: /* @__PURE__ */ _jsx2(stdin_default, {
                      FOdt_Q22J: z8b8c67Em,
                      height: '100%',
                      HihQ7sLzJ: HihQ7sLzJoivdpd,
                      id: 'XqmQ_dCHS',
                      JRpxGFjwH: false,
                      layoutId: 'XqmQ_dCHS',
                      variant: 'MHKwcgpiE',
                      width: '100%',
                      wsxRD5u41: true,
                    },),
                  },),
                },),
              },),
            ],
          },),
        },),
      },),
    },),
  },);
},);
var css5 = [
  '@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }',
  '.framer-cg3tY.framer-660f8t, .framer-cg3tY .framer-660f8t { display: block; }',
  '.framer-cg3tY.framer-kpfe1u { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 20px 20px 20px 20px; position: relative; width: 1440px; }',
  '.framer-cg3tY .framer-126at8h { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; height: min-content; justify-content: space-between; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1070px; }',
  '.framer-cg3tY .framer-1whzqmc { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; height: min-content; justify-content: space-between; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 1px; }',
  '.framer-cg3tY .framer-uu5sxp { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: 55px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }',
  '.framer-cg3tY .framer-6fu098 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }',
  '.framer-cg3tY .framer-n8ydhb { flex: none; height: 32px; overflow: visible; position: relative; width: 32px; }',
  '.framer-cg3tY .framer-1lyg715 { flex: none; height: 22px; left: calc(53.12500000000002% - 23px / 2); position: absolute; top: calc(53.12500000000002% - 22px / 2); width: 23px; }',
  '.framer-cg3tY .framer-1r15ghg { flex: none; height: 22px; left: calc(46.87500000000002% - 25px / 2); position: absolute; top: calc(50.00000000000002% - 22px / 2); width: 25px; }',
  '.framer-cg3tY .framer-3o3h6y { align-content: flex-start; align-items: flex-start; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }',
  '.framer-cg3tY .framer-1516upi-container, .framer-cg3tY .framer-7hzws2-container, .framer-cg3tY .framer-1wyhldt-container { flex: none; height: auto; position: relative; width: auto; }',
  '.framer-cg3tY .framer-10slqsx { align-content: flex-start; align-items: flex-start; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: flex-end; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 1px; }',
  '@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-cg3tY.framer-kpfe1u, .framer-cg3tY .framer-uu5sxp, .framer-cg3tY .framer-3o3h6y, .framer-cg3tY .framer-10slqsx { gap: 0px; } .framer-cg3tY.framer-kpfe1u > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-cg3tY.framer-kpfe1u > :first-child, .framer-cg3tY .framer-uu5sxp > :first-child, .framer-cg3tY .framer-3o3h6y > :first-child, .framer-cg3tY .framer-10slqsx > :first-child { margin-left: 0px; } .framer-cg3tY.framer-kpfe1u > :last-child, .framer-cg3tY .framer-uu5sxp > :last-child, .framer-cg3tY .framer-3o3h6y > :last-child, .framer-cg3tY .framer-10slqsx > :last-child { margin-right: 0px; } .framer-cg3tY .framer-uu5sxp > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-cg3tY .framer-3o3h6y > * { margin: 0px; margin-left: calc(16px / 2); margin-right: calc(16px / 2); } .framer-cg3tY .framer-10slqsx > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } }',
  '.framer-cg3tY.framer-v-4xgb8g.framer-kpfe1u { width: 730px; }',
  '.framer-cg3tY.framer-v-4xgb8g .framer-126at8h, .framer-cg3tY.framer-v-z75vc4 .framer-126at8h, .framer-cg3tY.framer-v-1ai4azp .framer-1516upi-container, .framer-cg3tY.framer-v-1ai4azp .framer-7hzws2-container { width: 100%; }',
  '.framer-cg3tY.framer-v-z75vc4.framer-kpfe1u, .framer-cg3tY.framer-v-1ai4azp.framer-kpfe1u { width: 390px; }',
  '.framer-cg3tY.framer-v-z75vc4 .framer-n8ydhb { -webkit-user-select: none; cursor: pointer; user-select: none; }',
  '.framer-cg3tY.framer-v-1ai4azp .framer-126at8h { flex: 1 0 0px; flex-direction: column; gap: 20px; justify-content: flex-start; width: 1px; }',
  '.framer-cg3tY.framer-v-1ai4azp .framer-1whzqmc { flex: none; width: 100%; }',
  '.framer-cg3tY.framer-v-1ai4azp .framer-n8ydhb { cursor: pointer; }',
  '.framer-cg3tY.framer-v-1ai4azp .framer-3o3h6y { flex-direction: column; width: 100%; }',
  '.framer-cg3tY.framer-v-1ai4azp .framer-10slqsx { align-content: center; align-items: center; flex: none; justify-content: center; width: 100%; }',
  '@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-cg3tY.framer-v-1ai4azp .framer-126at8h, .framer-cg3tY.framer-v-1ai4azp .framer-3o3h6y { gap: 0px; } .framer-cg3tY.framer-v-1ai4azp .framer-126at8h > * { margin: 0px; margin-bottom: calc(20px / 2); margin-top: calc(20px / 2); } .framer-cg3tY.framer-v-1ai4azp .framer-126at8h > :first-child, .framer-cg3tY.framer-v-1ai4azp .framer-3o3h6y > :first-child { margin-top: 0px; } .framer-cg3tY.framer-v-1ai4azp .framer-126at8h > :last-child, .framer-cg3tY.framer-v-1ai4azp .framer-3o3h6y > :last-child { margin-bottom: 0px; } .framer-cg3tY.framer-v-1ai4azp .framer-3o3h6y > * { margin: 0px; margin-bottom: calc(16px / 2); margin-top: calc(16px / 2); } }',
  ...css3,
  '.framer-cg3tY[data-border="true"]::after, .framer-cg3tY [data-border="true"]::after { content: ""; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }',
];
var FramerDxJuV9CJ2 = withCSS2(Component2, css5, 'framer-cg3tY',);
var stdin_default3 = FramerDxJuV9CJ2;
FramerDxJuV9CJ2.displayName = 'Top Navigation';
FramerDxJuV9CJ2.defaultProps = { height: 95, width: 1440, };
addPropertyControls2(FramerDxJuV9CJ2, {
  variant: {
    options: ['xwBQGw0tJ', 'SvR1VZu54', 'V8F1cMoI1', 'X7UmP87j7',],
    optionTitles: ['Desktop', 'Tablet', 'Mobile', 'Mobile Open',],
    title: 'Variant',
    type: ControlType2.Enum,
  },
  Nu3nX8xNj: { title: 'Signup', type: ControlType2.EventHandler, },
  xgYlpsZOl: { title: 'textToSpeechLink', type: ControlType2.Link, },
  HBUTJ3xey: { title: 'pricingLink', type: ControlType2.Link, },
  XqvwP0xPg: { title: 'logoLink', type: ControlType2.Link, },
  z8b8c67Em: { defaultValue: 'Signup or Login', displayTextArea: false, title: 'signupTitle', type: ControlType2.String, },
},);
addFonts2(FramerDxJuV9CJ2, [
  {
    explicitInter: true,
    fonts: [{
      family: 'Inter',
      source: 'google',
      style: 'normal',
      url: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZ1rib2Bg-4.woff2',
      weight: '700',
    },],
  },
  ...NavbarLinkItemFonts,
  ...ButtonFonts,
  ...getFontsFromSharedStyle(fonts3,),
], { supportsExplicitInterCodegen: true, },);

// virtual:nav
import { WithFramerBreakpoints, } from 'unframer/dist/react';
import { jsx, } from 'react/jsx-runtime';
stdin_default3.Responsive = (props,) => {
  return /* @__PURE__ */ jsx(WithFramerBreakpoints, { Component: stdin_default3, ...props, },);
};
var nav_default = stdin_default3;
export { nav_default as default, };
